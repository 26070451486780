<template>
  <div class="rules" style="display: flex;justify-content: center;width: 100%;text-align: left;">
    <div style="width: 95%">
      <h1>Vllshop关于发布特殊品类商品信息公告</h1>
      <hr/>

      <p class="up">一、食品类商品销售资质要求</p>
      <p class="up">一、基本资料：</p>
      <p>1）营业执照、税务登记证</p>
      <p>2）《食品经营许可证》或《食品生产许可证》</p>
      <p>3）出口食品生产企业备案/或出口食品种植种植场备案<em>（注1）</em></p>
      <p>4）办公场所证明</p>
      <p class="up">2、特定食品种类需要额外的资质证明：</p>
      <p class="up">1）经营预包装食品：</p>
      <p>提供预售食品细分市场记录级别以上市场监管通知书</p>
      <p class="up">2）食品添加剂生产厂家：</p>
      <p>2.1）须持有营业执照和《食品添加剂生产》。</p>
      <p>2.2）生产的食品添加剂如符合产品的定义，则适用于随后的关于预预包装的规定。</p>
      <p class="up">3）养殖类商品如货物、鹿、龟等：须提供专利养殖类商品。</p>
      <p>3.1）水产类货物，须提供《水生动物经营利用许可证》及商品回程进出口的证明（《进出口货物执行收货人备案》）。<em>（注2）</em></p>
      <p>3.2）紧急动植物出具的《企业允许进出口证明书》。<em>（注3）</em></p>
      <p class="up">4）农产品类商品，须提供：</p>
      <p>4.1）食品生产企业备案证明，并针对农产品需求备案证明，出境水果企业办理登记手续：如办理办理出口《水果园注册证书》或《出境水果包装厂注册证书》、<em>（注4）</em> （直属海关规定将登记的果园、包装厂海关总署备案。）</p>
      <p>4.2 一般性贸易公司提供所采购的农产品必需品检验证明）。</p>
      <p>4.3植物、植物和其他胶带动植物和其他胶带动植物的口岸产品：胶带或在报关单上盖上印章。<em>（注5）</em></p>
      <p class="up">5）远洋水产类商品，须提供：</p>
      <p>5.1）《农业农村部远洋渔业资质证书》及《远洋渔业捕捞许可》<em>（注6）</em></p>
      <p>5.2 若为贸易公司，需提供相应的授权书、合同，以及满足检验检疫贸易的证明）</p>
      <p class="up">6）酒水类商品，须提供：</p>
      <p>提供《食品经营许可证》</p>
      <p class="up">二、动物种苗类、林业或农作物种子类商品</p>
      <p>Vllshop平台不包括多肉植物后续的各种支持种苗、暂存类商品销售</p>
      <p class="up">三、保健品、减肥、壮阳、丰胸、医疗器械类商品</p>
      <p>Vllshop平台不保健品、减肥、壮阳支持、丰胸、医疗器械等商品销售</p>
      <p class="up">四、说明：</p>
      <p>1、食品类商家入驻，必须提供上述【食品类】中的【基本经营商品资料】，并根据具体经营商品对照【其他食品类】中【所需资质提供证明材料】提供相应的资质证明</p>
      <p>2.若提供的资料经核实，并以展开展示、盖章、PS等方式提供，SHOP提供的资料提供/照片展示行为/公章形式，提供内容明确完整相应措施，包括但不限于商品下解除、限制访问、交易限制、及时封禁、设施清退等措施。</p>
      <p>————————————————————————</p>
      <p><em>注1：</em>《食品生产企业备案管理规定（2018年）》第六条食品生产企业未依法履行其备案职责或经备案审查不符合出口要求的，修改产品不予出口（注：该规定即为规定） 2022 年 1 月 1 日废止）。及《中华人民共和国食品进出口安全管理办法》（总令第 249 号，将于 2022 年 1 月 1 日起实施）第三十九条出口食品原料生产现场监督管理备案、企业实施岸检验单监督审核、出口清单监督审核、出口食品监督管理清单、出口食品生产现场监督检查、食品生产清单和生产项目的组合。 </p>
      <p><em>注2：</em>《水生动物动物利用许可证（2019年修改）》第三十条属于性进出口活动的，必须由商品权的承担，并取得《经营利用单位证》方可进行没有商品进出口权和《经营利用证》的单位。受理机关不得受理其申请</p>
      <p><em>注3：</em>《 动物植物进出口证书管理办法》第三条规定，动物植物进出口证书管理办法。</p>
      <p><em>注4：</em> 2018年第三条第三次第四次检验检疫监督管理办法对输入国境地区水果签定的双边协议、规定或要求（或明确规定，或输入国境地区法律法规）与国家签注的水果园和出口厂的水果园和输厂的输入国家实施注册登记的结果。规定，且输入或国家法律规定未明确要求的，出境水果园、包装厂可向海关申请注册登记。</p>
      <p><em>注5：</em>《出境动植物绳索法》（2009）第二十条输出植物、动植物产品和其他动植物修改物，由口岸动植物检疫机关实施，经检验合格或经除除不合格的无害处理的，不准出境的，不准出境的。</p>
      <p><em>注6：</em>《远洋渔业管理》第六条 农村对远洋渔业管理项目管理和企业资格认定，并依法对远洋渔业船舶和人员进行监督管理。</p>

    </div>

  </div>
</template>

<script>
export default {
  name: 'ReleaseSpecialProducts'
}
</script>

<style scoped>

</style>
